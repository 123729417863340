import SelectionBox, { SelectionBoxProps } from '../SelectionBox/SelectionBox'

import { EuiFormRow } from '@elastic/eui'

type SelectionBoxFieldProps = SelectionBoxProps & {
    form: any // eslint-disable-line @typescript-eslint/no-explicit-any
    name: string
    label?: string
}

const SelectionBoxField = (props: SelectionBoxFieldProps) => {
    return (
        <EuiFormRow
            fullWidth={props.fullWidth}
            label={props.label}
            isInvalid={
                props.form.touched[props.name] &&
                !!props.form.errors[props.name]
            }
            error={props.form.errors[props.name]}
        >
            <SelectionBox {...props} />
        </EuiFormRow>
    )
}

export default SelectionBoxField
