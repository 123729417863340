import * as yup from 'yup'

import { CreateUserRequest, UpdateUserRequest } from '@services/users'
import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer } from '@elastic/eui'
import {
    PasswordField,
    SelectionBoxField,
    SwitchField,
    TextField,
} from '@components/form'
import { boolean, ref, string } from 'yup'
import {
    useCreateUserMutation,
    useFetchRolesQuery,
    useFetchTenantsQuery,
    useFindUserQuery,
    useUpdateUserMutation,
} from '@services/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DetailsPage } from '@components/layout'
import { Permissions } from '@services/auth'
import { Role } from '@services/roles'
import YupPassword from 'yup-password'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

YupPassword(yup)

const getUserValidationSchemas = (t: any) => {
    return useMemo(() => {
        const userSchema = yup.object({
            userName: string().required().label(t('users:userName')),
            firstName: string().required().label(t('users:firstName')),
            lastName: string().required().label(t('users:lastName')),
            email: string().optional().email().label(t('users:email')),
            isEnabled: boolean().required().label(t('users:is_enabled')),
            tenantId: string().required().label(t('users:tenant')),
        })

        const userCreateSchema = userSchema.shape({
            password: string()
                .required()
                .password()
                .min(6)
                .minNumbers(1)
                .minUppercase(1)
                .minLowercase(1)
                .minSymbols(1)
                .label(t('users:password')),
            confirmPassword: string()
                .required()
                .oneOf([ref('password')], t('users:password_not_match'))
                .label(t('users:confirm_password')),
        })

        const userUpdateSchema = userSchema.shape({
            password: string()
                .optional()
                .password()
                .min(6)
                .minNumbers(1)
                .minUppercase(1)
                .minLowercase(1)
                .minSymbols(1)
                .label(t('users:password')),
            confirmPassword: string()
                .optional()
                .oneOf([ref('password')], t('users:password_not_match'))
                .label(t('users:confirm_password')),
        })
        return { userCreateSchema, userUpdateSchema }
    }, [t])
}

const getRolesOptions = (
    ids: string[] | undefined,
    roles: Role[] | undefined
) => {
    if (!ids || !roles) return []

    return ids.map((id) => {
        const role = roles.find((r) => r.id === id)
        return {
            label: role?.name ?? '',
            value: id,
        }
    })
}

const UserDetailsPage = () => {
    const navigate = useNavigate()

    // eslint-disable-next-line prefer-const
    let { id } = useParams()
    const [skip, setSkip] = useState(true)
    const { data, isLoading } = useFindUserQuery({ id: id ?? '' }, { skip })

    const canCreateUser = useHasPermission(Permissions.user.canCreate)
    const canUpdateUser = useHasPermission(Permissions.user.canUpdate)

    const isEditing = useMemo(() => !!data, [data])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateUser) return false
        if (!isEditing && canCreateUser) return false

        return true
    }, [isEditing, canCreateUser, canUpdateUser])

    const { data: tenants } = useFetchTenantsQuery({})
    const { data: roles } = useFetchRolesQuery({})
    const [createUser, { isLoading: isCreating }] = useCreateUserMutation()
    const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation()
    const { t } = useTranslation(['common', 'users'])
    const { userCreateSchema, userUpdateSchema } = getUserValidationSchemas(t)
    const onSubmit = async (record: UpdateUserRequest | CreateUserRequest) => {
        try {
            if (data && id) {
                await updateUser({ ...record, id: id } as UpdateUserRequest)
            } else {
                await createUser(record as CreateUserRequest)
            }
            navigate('/users')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<UpdateUserRequest | CreateUserRequest>({
        initialValues: {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            isEnabled: true,
            tenantId: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: data && id ? userUpdateSchema : userCreateSchema,
        onSubmit,
    })

    useEffect(() => {
        if (id && id !== 'new') {
            setSkip(false)
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues({
                ...data,
                password: '',
                confirmPassword: '',
                roles: data?.roles?.map((r) => r.id) ?? [],
            })
        }
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('/users')}
            size="s"
            title={data ? t('users:edit_title') : t('users:create_title')}
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={isFormDisabled ? undefined : form.handleSubmit}
            onCancel={() => navigate('/users')}
        >
            <EuiForm>
                <TextField
                    form={form}
                    name="email"
                    isDisabled={isFormDisabled}
                    fullWidth
                    label={t('users:email')}
                    placeholder={t('users:email')}
                />
                <EuiSpacer />
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <TextField
                            form={form}
                            name="firstName"
                            isDisabled={isFormDisabled}
                            fullWidth
                            label={t('users:firstName')}
                            placeholder={t('users:firstName')}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <TextField
                            form={form}
                            name="lastName"
                            isDisabled={isFormDisabled}
                            fullWidth
                            label={t('users:lastName')}
                            placeholder={t('users:lastName')}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />

                <TextField
                    form={form}
                    name="userName"
                    isDisabled={isFormDisabled}
                    fullWidth
                    label={t('users:userName')}
                    placeholder={t('users:userName')}
                />

                <PasswordField
                    form={form}
                    name="password"
                    disabled={isFormDisabled}
                    fullWidth
                    autocomplete="new-password"
                    label={t('users:password')}
                    placeholder={t('users:password')}
                />

                <PasswordField
                    form={form}
                    name="confirmPassword"
                    disabled={isFormDisabled}
                    fullWidth
                    autocomplete="new-password"
                    label={t('users:confirm_password')}
                    placeholder={t('users:confirm_password')}
                />
                <SelectionBoxField
                    form={form}
                    name="roles"
                    disabled={isFormDisabled}
                    label={t('users:roles')}
                    fullWidth
                    multiple
                    isClearable={false}
                    value={getRolesOptions(form.values.roles, roles?.items)}
                    onChange={(value) => {
                        form.setFieldValue(
                            'roles',
                            value.map((v: any) => v.value)
                        )
                    }}
                    options={
                        roles?.items?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })) ?? []
                    }
                />
                <SelectionBoxField
                    form={form}
                    name="tenantId"
                    disabled={isFormDisabled}
                    label={t('users:tenant')}
                    fullWidth
                    isClearable={false}
                    value={form.values.tenantId}
                    onChange={(value) =>
                        form.setFieldValue('tenantId', value[0]?.value)
                    }
                    options={
                        tenants?.items?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })) ?? []
                    }
                />
                <SwitchField
                    form={form}
                    name="isEnabled"
                    isDisabled={isFormDisabled}
                    label={t('users:is_enabled')}
                    value={form.values.isEnabled}
                    onChange={(value) => form.setFieldValue('isEnabled', value)}
                />
            </EuiForm>
        </DetailsPage>
    )
}
export default UserDetailsPage
