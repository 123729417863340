import { EuiButton, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import {
    Query,
    getDefaultQuery,
    useDeleteDeviceMutation,
    useDeleteDevicesMutation,
    useFetchAgentsQuery,
    useFetchDevicesQuery,
} from '@services/api'
import { Table, TableColumn } from '@components/table'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Device } from '@services/devices'
import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DevicesPage = () => {
    const { t } = useTranslation(['common', 'devices'])
    const navigate = useNavigate()
    const [query, setQuery] = useState<Query>({
        ...getDefaultQuery(),
        filters: ['isTemplate==false'],
    })

    const canCreateDevices = useHasDependentPermissions('device', [
        'canView',
        'canCreate',
    ])
    const canUpdateDevices = useHasDependentPermissions('device', [
        'canView',
        'canUpdate',
    ])
    const canDeleteDevices = useHasDependentPermissions('device', ['canDelete'])

    const canViewAgents = useHasPermission(Permissions.agent.canView)
    const canViewDeviceTemplates = useHasPermission(
        Permissions.device.canViewTemplates
    )

    const { data: agents } = useFetchAgentsQuery({}, { skip: !canViewAgents })
    const { data, isLoading } = useFetchDevicesQuery(query)
    const [deleteDevice, { isLoading: isDeleteLoading }] =
        useDeleteDeviceMutation()
    const [deleteDevices, { isLoading: isDeletesLoading }] =
        useDeleteDevicesMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('devices:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('devices:filter_by_name'),
        },
        {
            field: 'isEnabled',
            name: t('devices:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('devices:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        ...(canViewAgents
            ? [
                  {
                      field: 'agent.name',
                      name: t('devices:agent'),
                      sortable: true,
                      filterable: true,
                      filterField: 'agentId',
                      filterType: 'options',
                      filterPlaceholder: t('devices:filter_by_agent'),
                      filterOptions:
                          agents?.items?.map((item) => ({
                              label: item.name,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateDevices ? 'onEdit' : 'onView']: (device: Device) =>
                navigate(`/devices/${device.id}`),
            onDelete: canDeleteDevices
                ? (device: Device) => deleteDevice(device.id)
                : undefined,
            customActions: canCreateDevices ? [
                {
                    icon: 'copy',
                    label: t('common:clone'),
                    description: t('common:clone_description'),
                    onClick: (device: Device) =>
                        navigate(`/devices/new?templateId=${device.id}`),
                },
            ] : undefined,
        },
    ]

    const handleOnChange = (query: Query) => setQuery({ ...query })

    return (
        <>
            <Outlet />
            <Page
                title={t('devices:title')}
                iconType="managementApp"
                items={
                    canCreateDevices
                        ? [
                              <EuiButton
                                  key="btn_new_device"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/devices/new')}
                              >
                                  {t('devices:new_device')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <>
                    <EuiTabs>
                        <EuiTab key="devices" name="Devices" isSelected={true}>
                            {t('devices:title')}
                        </EuiTab>
                        {canViewDeviceTemplates && (
                            <EuiTab
                                key="device-templates"
                                name="Device templates"
                                onClick={() => navigate('/deviceTemplates')}
                            >
                                {t('devices:templates_title')}
                            </EuiTab>
                        )}
                    </EuiTabs>
                    <EuiSpacer size="s" />
                    <Table<Device>
                        loading={
                            isLoading || isDeleteLoading || isDeletesLoading
                        }
                        items={data?.items ?? []}
                        totalCount={data?.totalCount ?? 0}
                        columns={columns}
                        onFilter={(filters) => setQuery({ ...query, filters })}
                        onFilterClear={() =>
                            setQuery({ ...query, filters: [] })
                        }
                        onChange={handleOnChange}
                        selectable={canDeleteDevices}
                        itemSelectable={() => true}
                        deleteSelectedRows={
                            canDeleteDevices
                                ? (items) =>
                                      deleteDevices(items.map((x) => x.id))
                                : undefined
                        }
                    />
                </>
            </Page>
        </>
    )
}
export default DevicesPage
