import { EuiComboBoxOptionOption } from '@elastic/eui'
import { Option } from '@services/options'
import i18n from '@locale/i18n'
import { parseAlarmSelectOptionValue } from '@services/options/options.service'

export enum SensorType {
    Ping = 'PING',
    WmiProcessor = 'WMI_PROCESSOR',
    WmiDisk = 'WMI_DISK',
    WmiMemory = 'WMI_MEMORY',
    WmiService = 'WMI_SERVICE',
    SnmpProcessor = 'SNMP_PROCESSOR',
    SnmpTraffic = 'SNMP_TRAFFIC',
    Ftp = 'FTP',
    PortCheck = 'PORTCHECK',
    Http = 'HTTP',
    SshProcessor = 'SSH_PROCESSOR',
    SshDisk = 'SSH_DISK',
    SshMemory = 'SSH_MEMORY',
    SshService = 'SSH_SERVICE',
}

export const SnmpDataPointKeys = Object.freeze({
    interfaceName: 'snmpTrafficInterfaceName',
    numOfInterfaces: 'snmpTrafficInterfaceNumberOfInterfaces',
    bandwith: {
        outgoing: 'snmpTrafficInterfaceOutgoingBandwidthMbps',
        incoming: 'snmpTrafficInterfaceIncomingBandwidthMbps',
        total: 'snmpTrafficInterfaceTotalBandwithMbps',
        max: 'snmpTrafficInterfaceMaximumBandwidthMbps',
    },
    errors: {
        outgoing: 'snmpTrafficInterfaceOutgoingErrors',
        incoming: 'snmpTrafficInterfaceIncomingErrors',
    },
    unicastPkts: {
        outgoing: 'snmpTrafficInterfaceOutgoingUnicastPackets',
        incoming: 'snmpTrafficInterfaceIncomingUnicastPackets',
    },
    nonUnicastPkts: {
        outgoing: 'snmpTrafficInterfaceOutgoingNonUnicastPackets',
        incoming: 'snmpTrafficInterfaceIncomingNonUnicastPackets',
    },
})

export const ChannelSensorTypes = [SensorType.WmiDisk, SensorType.SshDisk]

export const WmiServiceStates = {
    Running: 'Running',
    Stopped: 'Stopped',
}

export const WmiServiceStatuses = {
    Ok: 'OK',
    Unknown: 'Unknown',
}

export const SshServiceStates = {
    Running: 'running',
    Failed: 'failed',
    Exited: 'exited',
}

export const SshServiceStatuses = {
    Active: 'active',
    Failed: 'failed',
}

export const SensorHttpMethods = [
    'GET',
    'POST',
    'PUT',
    'DELETE',
    'PATCH',
] as const

export type SensorHttpMethod = (typeof SensorHttpMethods)[number]

export interface DetailsSensorRequest {
    name?: string
    templateName?: string
    isEnabled: boolean
    isTemplate: boolean
    isPaused?: boolean | undefined
    pausedUntil?: Date | undefined
    deviceId?: number
    categoryId?: number
    type: SensorType
    options?: Option[] | undefined
}

export interface Sensor {
    id: number
    name: string
    templateName?: string
    isEnabled: boolean
    isTemplate: boolean
    deviceId: number
    type: SensorType
    isPaused?: boolean | undefined
    pausedUntil?: Date | undefined
    monitoringStatus?:
        | 'Offline'
        | 'Online'
        | 'Error'
        | 'Warning'
        | 'Paused'
        | undefined
    lastMonitoringTimestamp?: Date
    options?: Option[] | undefined
}

export interface PauseSensorPayload {
    sensorId: number
    isPaused: boolean
    pausedUntil?: Date | undefined
}

export const getSensorTypeTranslation = (type: SensorType): string => {
    switch (type) {
        case SensorType.Ping:
            return i18n.t('sensors:type_ping')
        case SensorType.WmiProcessor:
            return i18n.t('sensors:type_wmi_processor')
        case SensorType.WmiDisk:
            return i18n.t('sensors:type_wmi_disk')
        case SensorType.WmiMemory:
            return i18n.t('sensors:type_wmi_memory')
        case SensorType.WmiService:
            return i18n.t('sensors:type_wmi_service')
        case SensorType.Http:
            return i18n.t('sensors:type_http')
        case SensorType.SnmpProcessor:
            return i18n.t('sensors:type_snmp_processor')
        case SensorType.SnmpTraffic:
            return i18n.t('sensors:type_snmp_traffic')
        case SensorType.Ftp:
            return i18n.t('sensors:type_ftp')
        case SensorType.PortCheck:
            return i18n.t('sensors:type_port_check')
        case SensorType.SshProcessor:
            return i18n.t('sensors:type_ssh_processor')
        case SensorType.SshDisk:
            return i18n.t('sensors:type_ssh_disk')
        case SensorType.SshMemory:
            return i18n.t('sensors:type_ssh_memory')
        case SensorType.SshService:
            return i18n.t('sensors:type_ssh_service')
        default:
            return 'Unknown'
    }
}

export const getWmiServiceComboboxOptions = () => [
    {
        label: i18n.t('options:alarm_wmi_service_state'),
        options: Object.entries(WmiServiceStates).map(([key, val]) => ({
            value: `WmiServiceState:${key}`,
            label: val,
        })),
    },
    {
        label: i18n.t('options:alarm_wmi_service_status'),
        options: Object.entries(WmiServiceStatuses).map(([key, val]) => ({
            value: `WmiServiceStatus:${key}`,
            label: val,
        })),
    },
]

export const getWmiServiceComboboxOptionsFromString = (
    option: string
): EuiComboBoxOptionOption[] => {
    if (typeof option !== 'string') return option

    const parsedValues = parseAlarmSelectOptionValue(option)
    const comboboxValues = parsedValues
        .map((x) => {
            const val = x.split(':')[1]
            return { label: val, value: x }
        })
        ?.filter((x) => x.value !== 'undefined')

    return comboboxValues
}

export const getSshServiceComboboxOptions = () => [
    {
        label: i18n.t('options:alarm_ssh_service_state'),
        options: Object.entries(SshServiceStates).map(([key, val]) => ({
            value: `SshServiceState:${key}`,
            label: val,
        })),
    },
    {
        label: i18n.t('options:alarm_ssh_service_status'),
        options: Object.entries(SshServiceStatuses).map(([key, val]) => ({
            value: `SshServiceStatus:${key}`,
            label: val,
        })),
    },
]

export const getSshServiceComboboxOptionsFromString = (
    option: string
): EuiComboBoxOptionOption[] => {
    if (typeof option !== 'string') return option

    const parsedValues = parseAlarmSelectOptionValue(option)
    const comboboxValues = parsedValues
        .map((x) => {
            const val = x.split(':')[1]
            return { label: val, value: x }
        })
        ?.filter((x) => x.value !== 'undefined')

    return comboboxValues
}

export const getSensorTagName = (
    sensor: Sensor | { name: string; type: SensorType }
): string => {
    if (
        sensor.type === SensorType.WmiService ||
        sensor.type === SensorType.SshService
    ) {
        return sensor.name
    }

    // If sensor type is number and not enum value, return the name
    if (!Object.values(SensorType).includes(sensor.type)) {
        console.error('Unknown sensor type:', sensor.type)
        return 'UNKNOWN'
    }

    return i18n.t(`sensors:short_type_${sensor.type.toLowerCase()}`)
}
