import { EuiBadge, EuiButton } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import {
    Query,
    getDefaultQuery,
    useDeleteUserMutation,
    useFetchTenantsQuery,
    useFetchUsersQuery,
} from '@services/api'
import { Table, TableColumn } from '@components/table'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { Role } from '@services/roles'
import { User } from '@services/users'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const UsersPage = () => {
    const { t } = useTranslation(['common', 'users'])
    const navigate = useNavigate()
    const [query, setQuery] = useState<Query>(getDefaultQuery())

    const canCreateUser = useHasDependentPermissions('user', [
        'canView',
        'canCreate',
    ])
    const canUpdateUser = useHasDependentPermissions('user', [
        'canView',
        'canUpdate',
    ])
    const canDeleteUser = useHasDependentPermissions('user', ['canDelete'])

    const canViewTenants = useHasPermission(Permissions.tenant.canView)
    const canViewRoles = useHasPermission(Permissions.role.canView)

    const { data: tenants } = useFetchTenantsQuery(
        {},
        { skip: !canViewTenants }
    )
    const { data, isLoading } = useFetchUsersQuery(query)
    const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation()

    const columns: TableColumn[] = [
        {
            field: 'userName',
            name: t('users:userName'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('users:filter_by_username'),
        },
        {
            field: 'firstName',
            name: t('users:firstName'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('users:filter_by_firstName'),
        },
        {
            field: 'lastName',
            name: t('users:lastName'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('users:filter_by_lastName'),
        },
        {
            field: 'email',
            name: t('users:email'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('users:filter_by_email'),
        },
        ...(canViewRoles
            ? [
                  {
                      field: 'roles',
                      name: t('users:roles'),
                      sortable: true,
                      filterable: true,
                      formatter: (roles: Role[]) => {
                          return (
                              <span>
                                  {roles.map((r) => (
                                      <EuiBadge key={r.id}>{r.name}</EuiBadge>
                                  ))}
                              </span>
                          )
                      },
                      // filterType: 'tags',
                      // filterPlaceholder: t('users:filter_by_email'),
                  } as TableColumn,
              ]
            : []),
        {
            field: 'isEnabled',
            name: t('users:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('users:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        ...(canViewTenants
            ? [
                  {
                      field: 'tenant.name',
                      name: t('users:tenant'),
                      sortable: true,
                      filterable: true,
                      filterField: 'tenantId',
                      filterType: 'options',
                      filterPlaceholder: t('users:filter_by_tenant'),
                      filterOptions:
                          tenants?.items?.map((item) => ({
                              label: item.name,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateUser ? 'onEdit' : 'onView']: (user: User) =>
                navigate(`/users/${user.id}`),
            onDelete: canDeleteUser
                ? (user: User) => deleteUser(user.id)
                : undefined,
        },
    ]

    const handleOnChange = (query: Query) => setQuery({ ...query })

    return (
        <>
            <Outlet />
            <Page
                title={t('users:title')}
                iconType="securityApp"
                items={
                    canCreateUser
                        ? [
                              <EuiButton
                                  key="btn_new_user"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/users/new')}
                              >
                                  {t('users:new_user')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<User>
                    loading={isLoading || isDeleteLoading}
                    items={data?.items ?? []}
                    totalCount={data?.totalCount ?? 0}
                    columns={columns}
                    onFilter={(filters) => setQuery({ ...query, filters })}
                    onFilterClear={() => setQuery({ ...query, filters: [] })}
                    onChange={handleOnChange}
                />
            </Page>
        </>
    )
}

export default UsersPage
