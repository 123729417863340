import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    useGeneratedHtmlId,
} from '@elastic/eui'
import NetworkLineChart, { ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'

import { formatAsPercentage } from '@utils/numbers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type PingChartProps = ChartProps

type PingChartLine = {
    key: string
    label: string
    unit?: string
    isStatus?: boolean
    isPercentage?: boolean
}

const PingChart = (props: PingChartProps) => {
    const { t } = useTranslation(['network'])
    const syncId = useGeneratedHtmlId({ prefix: 'ping-chart' })

    const lines: PingChartLine[] = [
        {
            key: 'pingStatus',
            label: t('network:data_key_ping_status'),
            isStatus: true,
        },
        {
            key: 'pingTime',
            label: t('network:data_key_ping_time'),
        },
        {
            key: 'pingPacketLoss',
            label: t('network:data_key_ping_packet_loss'),
            isPercentage: true,
        },
        {
            key: 'pingBytes',
            label: t('network:data_key_ping_bytes'),
        },
        {
            key: 'pingTtl',
            label: t('network:data_key_ping_ttl'),
        },
    ]
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const getYAxisDomain = (line: PingChartLine) => {
        if (line.isStatus) {
            return ['Unknown', 'TimedOut', 'Success', 'TimeExceeded']
        }
        if (line.isPercentage) {
            return [0, 100]
        }

        return undefined
    }

    return (
        <EuiFlexGroup direction="column">
            {lines.map((line, i) => (
                <EuiFlexItem key={i}>
                    <EuiTitle size="xs">
                        <h2 style={{ textAlign: 'center' }}>{line.label}</h2>
                    </EuiTitle>
                    <NetworkLineChart
                        height={250}
                        legendWidth={200}
                        syncId={syncId}
                        data={props.data}
                        query={props.query}
                        setQuery={props.setQuery}
                        lines={[line]}
                        yAxisType={line.isStatus ? 'category' : 'number'}
                        yAxisWidth={100}
                        yAxisDomain={getYAxisDomain(line)}
                        yAxisFormatter={(v) => {
                            if (line.isStatus) {
                                return t(`network:data_value_${v}`)
                            }
                            if (line.isPercentage) {
                                return formatAsPercentage(+v)
                            }
                            return v
                        }}
                        xAxis="timestamp"
                        xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                        xAxisDomain={xAxisDomain}
                    />
                </EuiFlexItem>
            ))}
        </EuiFlexGroup>
    )
}
export default PingChart
