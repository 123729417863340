import { CollectionResult, Query } from '../interfaces'
import {
    DetailsSensorRequest,
    PauseSensorPayload,
    Sensor,
} from '@services/sensors'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const sensorApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchSensors: builder.query<CollectionResult<Sensor>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Sensor'),
            query: (q) => ({
                url: 'sensors',
                method: 'GET',
                params: buildQueryParams(q, ['device']),
            }),
        }),
        findSensor: builder.query<Sensor, { id: number }>({
            providesTags: (result) => provideCacheTag(result, 'Sensor'),
            query: (q) => ({
                url: `sensors/${q.id}`,
                method: 'GET',
            }),
        }),
        createSensor: builder.mutation<Sensor, DetailsSensorRequest>({
            query: (req) => ({
                url: 'sensors',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: () =>
                invalidateCacheTag('Sensor')
                    .concat(['Option'])
                    .concat(['AgentNetworkTree'])
                    .concat(['GroupNetworkTree']),
        }),
        updateSensor: builder.mutation<Sensor, Sensor>({
            query: (req) => ({
                url: `sensors/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Sensor', result?.id).concat(['Option']),
        }),
        deleteSensor: builder.mutation<void, number>({
            query: (id) => ({
                url: `sensors/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Sensor', req).concat(['Option']),
        }),
        deleteSensors: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: 'sensors',
                method: 'DELETE',
                body: {
                    sensorIds: ids,
                },
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Sensor', req).concat(['Option']),
        }),
        pauseSensor: builder.mutation<void, PauseSensorPayload>({
            query: (req) => ({
                url: `sensors/${req.sensorId}/pause`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result, _error, req) =>
                invalidateCacheTag('Sensor', req.sensorId).concat(['Option']),
        }),
        changeSensorCategory: builder.mutation<
            void,
            { id: number; category: number | undefined }
        >({
            query: (req) => ({
                url: `sensors/${req.id}/category/${req.category ? req.category : ''}`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result, _error) =>
                invalidateCacheTag('Sensor').concat(['Option']),
        }),
        createSensorTemplate: builder.mutation<void, DetailsSensorRequest>({
            query: (req) => ({
                url: 'sensors/templates',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: () =>
                invalidateCacheTag('Sensor').concat(['Option']),
        }),
        updateSensorTemplate: builder.mutation<Sensor, Sensor>({
            query: (req) => ({
                url: `sensors/templates/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Sensor', result?.id).concat(['Option']),
        }),
    }),
})

export const {
    useFetchSensorsQuery,
    useFindSensorQuery,
    useCreateSensorMutation,
    useUpdateSensorMutation,
    useDeleteSensorMutation,
    useDeleteSensorsMutation,
    usePauseSensorMutation,
    useChangeSensorCategoryMutation,
    useCreateSensorTemplateMutation,
    useUpdateSensorTemplateMutation,
} = sensorApi
