import { Permissions } from '@services/auth'

export type NavItem = {
    id: string
    name: string
    icon?: string
    href?: string
    items?: NavItem[]
    permissions?: string[]
    alt?: {
        name?: string
        href?: string
        permissions?: string[]
    }
}

export const navigationItems: NavItem[] = [
    {
        id: 'alarms',
        name: 'navigation:alarms',
        icon: 'watchesApp',
        href: '/alarms',
        permissions: [Permissions.alarm.canView],
    },
    {
        id: 'network',
        name: 'navigation:network',
        icon: 'visualizeApp',
        href: '/network',
        permissions: [Permissions.network.canView],
    },
    {
        id: 'management',
        name: 'navigation:management',
        icon: 'managementApp',
        items: [
            {
                id: 'management.tenants',
                name: 'navigation:tenants',
                href: '/tenants',
                permissions: [Permissions.tenant.canView],
            },
            {
                id: 'management.agents',
                name: 'navigation:agents',
                href: '/agents',
                permissions: [Permissions.agent.canView],
            },
            {
                id: 'management.devices',
                name: 'navigation:devices',
                href: '/devices',
                permissions: [Permissions.device.canView],
                alt: {
                    name: 'navigation:deviceTemplates',
                    href: '/deviceTemplates',
                    permissions: [Permissions.device.canViewTemplates],
                },
            },
            {
                id: 'management.sensors',
                name: 'navigation:sensors',
                href: '/sensors',
                permissions: [Permissions.sensor.canView],
                alt: {
                    name: 'navigation:sensor_templates',
                    href: '/sensorTemplates',
                    permissions: [Permissions.sensor.canViewTemplates],
                },
            },
            {
                id: 'management.categories',
                name: 'navigation:categories',
                href: '/categories',
                permissions: [Permissions.category.canView],
            },
        ],
    },
    {
        id: 'security',
        name: 'navigation:security',
        icon: 'securityApp',
        items: [
            {
                id: 'security.users',
                name: 'navigation:users',
                href: '/users',
                permissions: [Permissions.user.canView],
            },
            {
                id: 'security.roles',
                name: 'navigation:roles',
                href: '/roles',
                permissions: [Permissions.role.canView],
            },
        ],
    },
]
