import { DEBOUNCE_DELAY, LIVE_POOLING_INTERVAL } from '@utils/globals'
import {
    EuiButton,
    EuiButtonGroup,
    EuiButtonIcon,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiProgress,
    EuiSpacer,
} from '@elastic/eui'
import {
    TreeMode,
    TreeNode,
    TreeState,
    getAllNodesKeys,
    getNodeIcon,
    getTreeNodes,
} from '@services/network'
import { setActiveNode, setTreeMode } from '@store/network'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
    useFetchNetworkAgentsQuery,
    useFetchNetworkGroupsQuery,
} from '@services/api'
import { useLocation, useNavigate } from 'react-router-dom'

import { Category } from '@services/categories'
import { Key } from 'rc-tree/lib/interface'
import NetworkTreeFilters from './NetworkTreeFilters'
import { Permissions } from '@services/auth'
import Tree from 'rc-tree'
import { setTreeParams } from '@store/network/network.slice'
import { useDebounce } from '@hooks/utils'
import { useHasPermission } from '@hooks/auth'
import useQueryParamsNetworkData from '@hooks/utils/useQueryParamsNetworkData'
import { useTranslation } from 'react-i18next'

type TreeParams = {
    expandedKeys: string[]
    autoExpandParent: boolean
}

type TreeCountersState = {
    errors: number
    warnings: number
}

const motion = {
    motionName: 'node-motion',
    motionAppear: false,
    onAppearStart: () => ({ height: 0 }),
    onAppearActive: (node: any) => ({ height: node.scrollHeight }),
    onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
    onLeaveActive: () => ({ height: 0 }),
}

let unfilteredNodes: TreeNode[] = []
let isExpanded = false
const defaultTreeState = {
    errors: false,
    warnings: false,
    expandAll: true,
    sort: true,
}

const NetworkTree = () => {
    const { t } = useTranslation(['common', 'network'])
    const navigate = useNavigate()
    const location = useLocation()

    // Permission
    const canViewGlobal = useHasPermission(Permissions.network.canViewGlobal)

    // Tree state
    const ref = useRef<Tree>(null)
    const dispatch = useAppDispatch()
    const {
        treeMode: storedTreeMode,
        refreshTree,
        treeParams,
    } = useAppSelector((state) => state.network)
    const treeMode = !canViewGlobal ? 'groups' : storedTreeMode

    const [treeItems, setTreeItems] = useState<TreeNode[]>([])
    const [counterState, setCounterState] = useState<TreeCountersState>({
        errors: 0,
        warnings: 0,
    })

    // Search state
    const [treeState, setTreeState] = useState<TreeState>(defaultTreeState)
    const [queryState, setQueryState] = useState<TreeState>(defaultTreeState)
    const debounce = useDebounce(treeState, DEBOUNCE_DELAY)

    // Data fetching queries
    const {
        data: groups,
        isLoading: isGroupsLoading,
        refetch: groupsRefetch,
    } = useFetchNetworkGroupsQuery(queryState, {
        skip: treeMode !== 'groups',
        pollingInterval: LIVE_POOLING_INTERVAL,
    })
    const {
        data: agents,
        isLoading: isAgentsLoading,
        refetch: agentsRefetch,
    } = useFetchNetworkAgentsQuery(queryState, {
        skip: treeMode !== 'agents',
        pollingInterval: LIVE_POOLING_INTERVAL,
    })

    // Tree handlers
    const handleOnExpand = (expandedKeys: Key[]) => {
        dispatch(
            setTreeParams({
                expandedKeys: expandedKeys as string[],
                autoExpandParent: false,
            })
        )
    }

    const expandAllNodes = () => {
        dispatch(
            setTreeParams({
                expandedKeys: getAllNodesKeys(treeItems),
                autoExpandParent: false,
            })
        )
        isExpanded = true
    }

    const collapseAllNodes = () => {
        dispatch(
            setTreeParams({
                expandedKeys: [],
                autoExpandParent: false,
            })
        )
        isExpanded = false
    }

    const forceRefresh = () => {
        if (treeMode === 'groups') {
            groupsRefetch()
        } else {
            agentsRefetch()
        }
    }

    const TreeView = useMemo(
        () => (
            <Tree
                ref={ref}
                motion={motion}
                showLine
                icon={(node) => getNodeIcon((node as any).data.type)}
                onExpand={handleOnExpand}
                expandedKeys={treeParams.expandedKeys}
                autoExpandParent={treeParams.autoExpandParent}
                key={`tree-view-${treeMode}`}
                defaultExpandAll
                treeData={treeItems}
            />
        ),
        [treeItems, treeParams]
    )

    const handleOnAddClick = () => {
        switch (treeMode) {
            case 'agents':
                navigate('/agents/new')
                break
            case 'groups':
                navigate('/network/group/new')
                break
        }
    }

    const handleOnCategoryChange = (categories: Category[]) => {
        setTreeState({
            ...queryState,
            categories: categories.map((c) => c.id),
        })
    }

    const getTreeModes = () => {
        const modes = [
            {
                id: 'groups',
                label: t('network:groups'),
            },
        ]

        if (canViewGlobal) {
            modes.push({
                id: 'agents',
                label: t('network:agents'),
            })
        }

        return modes
    }

    // Setting tree items
    useEffect(() => {
        const data = treeMode === 'groups' ? groups : agents
        if (data) {
            const newNodes = getTreeNodes(data.items, treeMode)
            setTreeItems(newNodes)
            setCounterState({ errors: data.errors, warnings: data.warnings })
            unfilteredNodes = [...newNodes]
        }
    }, [groups, agents, treeMode])

    useEffect(() => {
        if (treeState.expandAll) {
            expandAllNodes()
        } else {
            collapseAllNodes()
        }
    }, [treeState.expandAll])

    useEffect(() => {
        forceRefresh()
    }, [refreshTree])

    useEffect(() => {
        if (debounce) {
            setQueryState({ ...debounce, sessionId: Date.now() })
        }
    }, [debounce])

    // Find the sensor/device from the URL query params and the group data
    const { sensor, device } = useQueryParamsNetworkData(location, groups)

    // If a sensor or device matching the URL query params has been found, set it as the active node
    useEffect(() => {
        if (sensor) {
            dispatch(
                setActiveNode({
                    data: { ...sensor },
                    type: 'sensor',
                })
            )
            return
        }

        if (device) {
            dispatch(
                setActiveNode({
                    data: { ...device },
                    type: 'device',
                })
            )
        }
    }, [sensor, device])

    return (
        <>
            <div>
                <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonGroup
                            legend="tree mode"
                            color="text"
                            buttonSize="compressed"
                            options={getTreeModes()}
                            idSelected={treeMode}
                            onChange={(id) =>
                                dispatch(setTreeMode(id as TreeMode))
                            }
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="xs" alignItems="center">
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    title={t(
                                        treeState.expandAll
                                            ? 'network:collapse_all'
                                            : 'network:expand_all'
                                    )}
                                    aria-label={t(
                                        treeState.expandAll
                                            ? 'network:collapse_all'
                                            : 'network:expand_all'
                                    )}
                                    display={
                                        treeState.expandAll ? 'fill' : 'empty'
                                    }
                                    iconType="submodule"
                                    size="xs"
                                    onClick={() =>
                                        setTreeState({
                                            ...treeState,
                                            expandAll: !treeState.expandAll,
                                        })
                                    }
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    title={t('network:tree_sort')}
                                    aria-label={t('network:tree_sort')}
                                    iconType="menuDown"
                                    size="xs"
                                    display={treeState.sort ? 'fill' : 'empty'}
                                    onClick={() =>
                                        setTreeState({
                                            ...treeState,
                                            sort: !treeState.sort,
                                        })
                                    }
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    title={t(`network:no_${treeMode}_add`)}
                                    aria-label={t(`network:no_${treeMode}_add`)}
                                    iconType="plus"
                                    size="xs"
                                    onClick={handleOnAddClick}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="m" />
                <NetworkTreeFilters
                    onCategoryChange={handleOnCategoryChange}
                    onSearch={(value) =>
                        setTreeState({ ...treeState, search: value })
                    }
                />
                <EuiSpacer size="m" />
                <div style={{ position: 'relative' }}>
                    {isGroupsLoading || isAgentsLoading ? (
                        <>
                            <EuiProgress
                                size="xs"
                                color="primary"
                                position="absolute"
                            />
                            <EuiSpacer size="s" />
                        </>
                    ) : null}
                    <div
                        className="eui-yScrollWithShadows"
                        aria-label={'tree-view'}
                        style={{ minWidth: 300, height: 'calc(100vh - 363px)' }}
                    >
                        {treeItems && treeItems.length > 0 && TreeView}
                        {!treeItems ||
                            (treeItems.length === 0 && (
                                <EuiEmptyPrompt
                                    icon={
                                        <EuiIcon
                                            type="questionInCircle"
                                            size="xxl"
                                            color="subdued"
                                        />
                                    }
                                    title={
                                        <h4>
                                            {t(`network:no_${treeMode}_title`)}
                                        </h4>
                                    }
                                    body={
                                        <p>
                                            {t(`network:no_${treeMode}_text`)}
                                        </p>
                                    }
                                    actions={
                                        <EuiButton
                                            color="primary"
                                            fill
                                            onClick={handleOnAddClick}
                                        >
                                            {t(`network:no_${treeMode}_add`)}
                                        </EuiButton>
                                    }
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NetworkTree
