import { EuiButton, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import {
    Query,
    getDefaultQuery,
    useDeleteSensorMutation,
    useDeleteSensorsMutation,
    useFetchDevicesQuery,
    useFetchSensorsQuery,
} from '@services/api'
import { Sensor, SensorType, getSensorTypeTranslation } from '@services/sensors'
import { Table, TableColumn } from '@components/table'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { getOptionsByEnum } from '@utils/enums'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SensorsPage = () => {
    const { t } = useTranslation(['common', 'sensors'])
    const navigate = useNavigate()
    const [query, setQuery] = useState<Query>({
        ...getDefaultQuery(),
        filters: ['deviceIsTemplate==false'],
    })

    const canCreateSensor = useHasDependentPermissions('sensor', [
        'canView',
        'canCreate',
    ])
    const canUpdateSensor = useHasDependentPermissions('sensor', [
        'canView',
        'canUpdate',
    ])
    const canDeleteSensor = useHasDependentPermissions('sensor', ['canDelete'])
    const canViewSensorTemplates = useHasDependentPermissions('sensor', [
        'canViewTemplates',
    ])
    const canViewDevices = useHasPermission(Permissions.device.canView)

    const { data: devices } = useFetchDevicesQuery(
        {
            ...getDefaultQuery(),
            filters: ['isTemplate==false'],
        },
        { skip: !canViewDevices }
    )
    const { data, isLoading } = useFetchSensorsQuery(query)
    const [deleteSensor, { isLoading: isDeleteLoading }] =
        useDeleteSensorMutation()
    const [deleteSensors, { isLoading: isDeletesLoading }] =
        useDeleteSensorsMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('sensors:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('sensors:filter_by_name'),
        },
        {
            field: 'isEnabled',
            name: t('sensors:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('sensors:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        {
            field: 'type',
            name: t('sensors:type'),
            formatter: (v: any) => <span>{getSensorTypeTranslation(v)}</span>,
            sortable: true,
            filterable: true,
            filterField: 'type',
            filterType: 'options',
            filterPlaceholder: t('sensors:filter_by_type'),
            filterOptions:
                getOptionsByEnum(SensorType).map(({ value }) => ({
                    value,
                    label: getSensorTypeTranslation(value),
                })) ?? [],
        },
        ...(canViewDevices
            ? [
                  {
                      field: 'device.name',
                      name: t('sensors:device'),
                      sortable: true,
                      filterable: true,
                      filterField: 'deviceId',
                      filterType: 'options',
                      filterPlaceholder: t('sensors:filter_by_device'),
                      filterOptions:
                          devices?.items?.map((item) => ({
                              label: item.name,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateSensor ? 'onEdit' : 'onView']: (sensor: Sensor) =>
                navigate(`/sensors/${sensor.id}`),
            onDelete: canDeleteSensor
                ? (sensor: Sensor) => deleteSensor(sensor.id)
                : undefined,
        },
    ]

    const handleOnChange = (query: Query) => setQuery({ ...query })

    return (
        <>
            <Outlet />
            <Page
                title={t('sensors:title')}
                iconType="managementApp"
                items={
                    canCreateSensor
                        ? [
                              <EuiButton
                                  key="btn_new_sensor"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/sensors/new')}
                              >
                                  {t('sensors:new_sensor')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <>
                    <EuiTabs>
                        <EuiTab key="sensors" name="Sensors" isSelected={true}>
                            Sensors
                        </EuiTab>
                        {canViewSensorTemplates && (
                            <EuiTab
                                key="sensor-templates"
                                name="Sensor templates"
                                onClick={() => navigate('/sensorTemplates')}
                            >
                                {t('sensors:templates_title')}
                            </EuiTab>
                        )}
                    </EuiTabs>
                    <EuiSpacer size="s" />
                    <Table<Sensor>
                        loading={
                            isLoading || isDeleteLoading || isDeletesLoading
                        }
                        items={data?.items ?? []}
                        totalCount={data?.totalCount ?? 0}
                        columns={columns}
                        onFilter={(filters) => setQuery({ ...query, filters })}
                        onFilterClear={() =>
                            setQuery({ ...query, filters: [] })
                        }
                        onChange={handleOnChange}
                        selectable={canDeleteSensor}
                        itemSelectable={() => true}
                        deleteSelectedRows={
                            canDeleteSensor
                                ? (items) =>
                                      deleteSensors(items.map((x) => x.id))
                                : undefined
                        }
                    />
                </>
            </Page>
        </>
    )
}
export default SensorsPage
