import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useEffect, useMemo, useState } from 'react'

import { Device } from '@services/devices'
import { Group } from '@services/groups'
import { Location } from 'react-router-dom'
import { NetworkCollection } from '@services/network'
import { Sensor } from '@services/sensors'
import { getNumberOrDefault } from '@utils/numbers'
import { setTreeMode } from '@store/network'

const useQueryParamsNetworkData = (
    location: Location,
    groups: NetworkCollection<Group> | undefined
) => {
    const { treeMode } = useAppSelector((state) => state.network)
    const dispatch = useAppDispatch()

    const [sensor, setSensor] = useState<Sensor | undefined>(undefined)
    const [device, setDevice] = useState<Device | undefined>(undefined)

    const searches = useMemo(() => {
        if (treeMode !== 'groups') dispatch(setTreeMode('groups'))

        const searchStrings = location.search
            ?.substring(1, location.search.length)
            .split('&')

        const searchObject: { [key: string]: number } = searchStrings?.reduce(
            (acc, curr) => {
                const entry = curr.split('=')

                if (entry[0] && entry[1]) {
                    return {
                        ...acc,
                        [entry[0]]: getNumberOrDefault(entry[1], 0),
                    }
                }

                return acc
            },
            {}
        )

        return searchObject
    }, [location])

    const findDevice = (deviceId: number, group: Group): Device | undefined => {
        const device = group.devices?.find((d) => d.id === deviceId)

        if (device) return device

        let subDevice = undefined

        if (group.subGroups && group.subGroups.length)
            group.subGroups.forEach((g) => {
                subDevice ??= findDevice(deviceId, g)
            })

        return subDevice
    }

    const findSensor = (sensorId: number, group: Group): Sensor | undefined => {
        const sensor = group.devices
            ?.flatMap((d) => d.sensors)
            ?.find((s) => s?.id === sensorId)

        if (sensor) return sensor

        let subSensor = undefined

        if (group.subGroups && group.subGroups.length)
            group.subGroups.forEach((g) => {
                subSensor ??= findSensor(sensorId, g)
            })

        return subSensor
    }

    useEffect(() => {
        const sensorId = searches['sensorId']
        const deviceId = searches['deviceId']

        let sensor = undefined
        let device = undefined

        if (sensorId) {
            groups?.items.forEach((g) => {
                sensor ??= findSensor(sensorId, g)
            })
        }

        if (deviceId) {
            groups?.items.forEach((g) => {
                device ??= findDevice(deviceId, g)
            })
        }

        setSensor(sensor)
        setDevice(device)
    }, [groups, searches])

    return { sensor, device }
}

export default useQueryParamsNetworkData
