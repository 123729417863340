import * as yup from 'yup'

import {
    CreateRoleRequest,
    UpdateRoleRequest,
    getRolePermissions,
} from '@services/roles'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiHorizontalRule,
    EuiIcon,
    EuiPanel,
    EuiSideNav,
    EuiSwitch,
    EuiText,
    EuiTitle,
    htmlIdGenerator,
} from '@elastic/eui'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    useCreateRoleMutation,
    useFindRoleQuery,
    useUpdateRoleMutation,
} from '@services/api'
import { useNavigate, useParams } from 'react-router-dom'

import { DetailsPage } from '@components/layout'
import { Permissions } from '@services/auth'
import { TextField } from '@components/form'
import { pascalToCamelCase } from '@utils/strings'
import { string } from 'yup'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const getDefaultPermissions = () =>
    Object.values(Permissions)
        .map(Object.values)
        .flat()
        .reduce(
            (acc, curr) => ({ ...acc, [pascalToCamelCase(curr)]: false }),
            {}
        )

const RoleDetailsPage = () => {
    const { t } = useTranslation(['common', 'roles'])
    const navigate = useNavigate()

    const canCreateRole = useHasPermission(Permissions.role.canCreate)
    const canUpdateRole = useHasPermission(Permissions.role.canUpdate)

    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
    const [activePermission, setActivePermission] = useState<
        | {
              category: string
              permission: string
          }
        | undefined
    >(undefined)
    const [openNavItems, setOpenNavItems] = useState<string[]>([])

    // eslint-disable-next-line prefer-const
    let { id } = useParams()

    const { data, isLoading } = useFindRoleQuery(
        { id: id || '' },
        { skip: !id || id === 'new' }
    )
    const [createRole, { isLoading: isCreating }] = useCreateRoleMutation()
    const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation()

    const permissions = useMemo(
        () => getRolePermissions(data?.permissions || getDefaultPermissions()),
        [data]
    )

    const isEditing = useMemo(() => !!data, [data])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateRole) return false
        if (!isEditing && canCreateRole) return false

        return true
    }, [isEditing, canCreateRole, canUpdateRole])

    const schema = useMemo(
        () =>
            yup.object({
                name: string().required().label(t('roles:name')),
            }),
        [t]
    )

    const isSelected = useCallback(
        (p: string) => selectedPermissions.includes(p),
        [selectedPermissions]
    )

    const onSubmit = async (record: CreateRoleRequest | UpdateRoleRequest) => {
        try {
            if (data && id) {
                await updateRole({
                    ...record,
                    permissions: selectedPermissions,
                } as UpdateRoleRequest)
            } else {
                await createRole({
                    ...record,
                    permissions: selectedPermissions,
                } as CreateRoleRequest)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleOnPermissionChange = (checked: boolean, p: string) => {
        if (checked) {
            setSelectedPermissions((prev) => [...prev, p])
        } else {
            setSelectedPermissions((prev) => [...prev].filter((x) => x !== p))
        }
    }

    const isNavItemOpen = (item: string) => openNavItems.indexOf(item) > -1

    const handleOnNavItemClick = useCallback(
        (item: string) => {
            const index = openNavItems.indexOf(item)
            if (index > -1)
                setOpenNavItems((prev) => [...prev].filter((x) => x !== item))
            else setOpenNavItems((prev) => [...prev, item])
        },
        [openNavItems]
    )

    const getPermissionCategory = useCallback(
        (category: string) => ({
            id: htmlIdGenerator(category)(),
            name: t(`roles:permissions:${category}`),
            isSelected: isNavItemOpen(category),
            onClick: () => handleOnNavItemClick(category),
            items: permissions[category]?.map((p) => ({
                id: p,
                name: (
                    <EuiText
                        color={isSelected(p) ? 'accent' : 'subdued'}
                        size="xs"
                        style={{
                            maxWidth: '10vw',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {t(`roles:permissions:${p}`)}
                    </EuiText>
                ),
                onClick: () =>
                    setActivePermission({ category: category, permission: p }),
            })),
        }),
        [activePermission, isSelected, permissions, handleOnNavItemClick]
    )

    const getPermissionDetails = useCallback(
        (category: string, isDisabled?: boolean) => (
            <EuiFlexItem key={category} grow={false}>
                <EuiCard
                    title={t(`roles:permissions:${category}`)}
                    key={category}
                    paddingSize="s"
                    titleSize="xs"
                    textAlign="left"
                    display={isSelected(category) ? 'success' : 'transparent'}
                    onClick={() =>
                        isFormDisabled
                            ? undefined
                            : handleOnPermissionChange(
                                  !isSelected(category),
                                  category
                              )
                    }
                    description={
                        <EuiFlexGroup
                            alignItems="center"
                            justifyContent="spaceBetween"
                        >
                            <EuiFlexItem>
                                {t(`roles:permissions:${category}_description`)}
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiSwitch
                                    label={t('common:enabled')}
                                    showLabel={false}
                                    compressed
                                    disabled={isDisabled}
                                    checked={isSelected(category)}
                                    onChange={(e) =>
                                        handleOnPermissionChange(
                                            e.target.checked,
                                            category
                                        )
                                    }
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    }
                />
            </EuiFlexItem>
        ),
        [isSelected, handleOnPermissionChange]
    )

    const form = useFormik<CreateRoleRequest | UpdateRoleRequest>({
        initialValues: {
            name: '',
            permissions: [],
        },
        validationSchema: schema,
        onSubmit,
    })

    useEffect(() => {
        if (data?.permissions) {
            const selected = Object.keys(data.permissions)
                .filter((x) => data.permissions[x] === true)
                .map((x) => x)
            setSelectedPermissions(selected)
            form.setValues({ ...data, permissions: selected })
            const [cat, perm] = Object.keys(data.permissions)[0].split('.')
            if (activePermission === undefined)
                setActivePermission({
                    category: cat,
                    permission: perm,
                })
            if (!openNavItems.length) setOpenNavItems([cat])
        }
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('/roles')}
            title={
                isEditing && canUpdateRole
                    ? t('roles:edit_title')
                    : isEditing && !canUpdateRole
                      ? t('roles:view_title')
                      : t('roles:create_title')
            }
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={isFormDisabled ? undefined : form.handleSubmit}
            onCancel={() => navigate('/roles')}
        >
            <EuiForm>
                <TextField
                    form={form}
                    name="name"
                    isDisabled={isFormDisabled}
                    fullWidth
                    label={t('roles:name')}
                    placeholder={t('roles:name')}
                />
            </EuiForm>
            <EuiHorizontalRule margin="m" />

            <EuiFlexGroup>
                <EuiFlexItem grow={1}>
                    <EuiPanel
                        className="eui-yScroll"
                        hasBorder={false}
                        hasShadow={false}
                        paddingSize="xs"
                        style={{
                            maxHeight: '76vh',
                            overflowY: 'auto',
                        }}
                    >
                        <EuiSideNav
                            items={[
                                {
                                    id: htmlIdGenerator('agent')(),
                                    name: t('roles:permissions:agent'),
                                    icon: <EuiIcon type="consoleApp" />,
                                    items: [getPermissionCategory('agent')],
                                },
                                {
                                    id: htmlIdGenerator('alarm')(),
                                    name: t('roles:permissions:alarm'),
                                    icon: <EuiIcon type="watchesApp" />,
                                    items: [getPermissionCategory('alarm')],
                                },
                                {
                                    id: htmlIdGenerator('category')(),
                                    name: t('roles:permissions:category'),
                                    icon: <EuiIcon type="spacesApp" />,
                                    items: [getPermissionCategory('category')],
                                },
                                {
                                    id: htmlIdGenerator('device')(),
                                    name: t('roles:permissions:device'),
                                    icon: <EuiIcon type="appSearchApp" />,
                                    items: [getPermissionCategory('device')],
                                },
                                {
                                    id: htmlIdGenerator('group')(),
                                    name: t('roles:permissions:group'),
                                    icon: <EuiIcon type="indexPatternApp" />,
                                    items: [getPermissionCategory('group')],
                                },
                                {
                                    id: htmlIdGenerator('network')(),
                                    name: t('roles:permissions:network'),
                                    icon: <EuiIcon type="visualizeApp" />,
                                    items: [getPermissionCategory('network')],
                                },
                                {
                                    id: htmlIdGenerator('role')(),
                                    name: t('roles:permissions:role'),
                                    icon: (
                                        <EuiIcon type="advancedSettingsApp" />
                                    ),
                                    items: [getPermissionCategory('role')],
                                },
                                {
                                    id: htmlIdGenerator('sensor')(),
                                    name: t('roles:permissions:sensor'),
                                    icon: <EuiIcon type="monitoringApp" />,
                                    items: [getPermissionCategory('sensor')],
                                },
                                {
                                    id: htmlIdGenerator('tenant')(),
                                    name: t('roles:permissions:tenant'),
                                    icon: (
                                        <EuiIcon type="securityAnalyticsApp" />
                                    ),
                                    items: [getPermissionCategory('tenant')],
                                },
                                {
                                    id: htmlIdGenerator('user')(),
                                    name: t('roles:permissions:user'),
                                    icon: <EuiIcon type="usersRolesApp" />,
                                    items: [getPermissionCategory('user')],
                                },
                            ]}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={3}>
                    {activePermission && (
                        <>
                            <EuiTitle>
                                <h3>
                                    {t(
                                        `roles:permissions:${activePermission.category}`
                                    )}
                                </h3>
                            </EuiTitle>
                            <EuiHorizontalRule margin="m" />
                            <EuiFlexGroup direction="column">
                                {permissions[activePermission.category] &&
                                    permissions[activePermission.category].map(
                                        (p) =>
                                            getPermissionDetails(
                                                p,
                                                isFormDisabled
                                            )
                                    )}
                            </EuiFlexGroup>
                        </>
                    )}
                </EuiFlexItem>
            </EuiFlexGroup>
        </DetailsPage>
    )
}

export default RoleDetailsPage
