export interface Role {
    id: string
    name: string
    cannotBeDeleted: boolean
}

export interface RoleDetails extends Role {
    permissions: { [key: string]: boolean }
}

export interface CreateRoleRequest {
    name: string
    permissions: string[]
}

export interface UpdateRoleRequest extends CreateRoleRequest {
    id: string
}

export const getRolePermissions = (permissions?: { [key: string]: boolean }) =>
    Object.keys(permissions ?? {}).reduce(
        (acc, permission) => {
            const [parent, child] = permission.split('.')
            if (!acc[parent]) {
                acc[parent] = []
            }
            if (child) {
                acc[parent].push(permission)
            }
            return acc
        },
        {} as Record<string, string[]>
    )
