import {
    AlarmAcknowledgement,
    TimeUnits,
    getPauseTimeAmount,
} from '@services/alarms'
import {
    EuiButton,
    EuiDescriptionList,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiTextArea,
    EuiTitle,
} from '@elastic/eui'
import { useAcknowledgeAlarmMutation, useFindAlarmQuery } from '@services/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DetailsPage } from '@components/layout'
import { Permissions } from '@services/auth'
import { formatFullDate } from '@utils/dates'
import { formatNumber } from '@utils/numbers'
import { parseAlarmOptionRange } from '@services/options'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const AlarmDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'alarms'])
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false)
    const [isPaused, setIsPaused] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>(1)
    const [note, setNote] = useState<string | undefined>()
    const [unit, setUnit] = useState<(typeof TimeUnits)[number]>('hours')
    const [acknowledgeAlarm, { isLoading: isPosting }] =
        useAcknowledgeAlarmMutation()

    const { id } = useParams()
    const [skip, setSkip] = useState(true)
    const { data, isLoading } = useFindAlarmQuery({ id: id ?? '' }, { skip })

    const canAcknowledgeAlarm = useHasPermission(
        Permissions.alarm.canAcknowledge
    )
    const canPauseSensor = useHasPermission(Permissions.sensor.canPause)

    const formatRange = (
        values: [number, number] | undefined
    ): string | undefined =>
        values ? `${values[0]} - ${values[1]}` : undefined

    const alarmDescriptionItems = useMemo(() => {
        if (!data) {
            return []
        }

        const info = [
            {
                title: t('alarms:timestamp'),
                description: formatFullDate(data.created),
            },
            {
                title: t('alarms:agent'),
                description: data.additionalData.agent ?? '',
            },
            {
                title: t('alarms:device'),
                description: data.additionalData.device ?? '',
            },
            {
                title: t('alarms:sensor'),
                description: data.additionalData.name ?? '',
            },
            {
                title: t('alarms:type'),
                description: t(`alarms:type_${data.type}`),
            },
        ]

        if (data.additionalData.exception) {
            info.push({
                title: t('alarms:exception'),
                description: data.additionalData.exception,
            })
        }

        if (data.additionalData.errorLimit) {
            info.push({
                title: t('alarms:error_limit'),
                description:
                    formatRange(
                        parseAlarmOptionRange(data.additionalData.errorLimit)
                    ) || formatNumber(data.additionalData.errorLimit),
            })
        }

        if (data.additionalData.warningLimit) {
            info.push({
                title: t('alarms:warning_limit'),
                description:
                    formatRange(
                        parseAlarmOptionRange(data.additionalData.warningLimit)
                    ) || formatNumber(data.additionalData.warningLimit),
            })
        }

        if (data.additionalData.currentValue) {
            info.push({
                title: t('alarms:current_value'),
                description: isNaN(Number(data.additionalData.currentValue))
                    ? data.additionalData.currentValue
                    : formatNumber(data.additionalData.currentValue),
            })
        }

        if (data.isAcknowledged && data.acknowledgedTimestamp) {
            info.push({
                title: t('alarms:acknowledgedTimestamp'),
                description: formatFullDate(data.acknowledgedTimestamp),
            })
        }

        if (data.additionalData.acknowledgedUser) {
            info.push({
                title: t('alarms:acknowledgedByUser'),
                description: data.additionalData.acknowledgedUser,
            })
        }

        if (data.additionalData.acknowledgedNote) {
            info.push({
                title: t('alarms:acknowledge_note'),
                description: data.additionalData.acknowledgedNote,
            })
        }

        return info
    }, [data])

    const handleOnSave = async () => {
        try {
            const req: AlarmAcknowledgement = {
                alarmIds: [data?.id ?? ''],
                isAcknowledged,
                isPaused,
                note,
                pauseTimeAmount: getPauseTimeAmount(unit, amount),
            }

            await acknowledgeAlarm(req).unwrap()
            navigate('..')
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (id) {
            setSkip(false)
        }
    }, [id])

    return (
        <DetailsPage
            onClose={() => navigate('/alarms')}
            size="s"
            title={t('alarms:details')}
            loading={isLoading}
            onCancel={() => navigate('/alarms')}
            confirmButton={
                !data?.isAcknowledged && (isAcknowledged || isPaused) ? (
                    <EuiButton
                        color="primary"
                        fill
                        size="s"
                        iconType="check"
                        disabled={!canAcknowledgeAlarm}
                        onClick={handleOnSave}
                    >
                        {t('common:save')}
                    </EuiButton>
                ) : undefined
            }
        >
            <EuiDescriptionList listItems={alarmDescriptionItems} />
            <EuiSpacer />

            <>
                {!data?.isAcknowledged && (
                    <>
                        <EuiTitle size="xs">
                            <h3>{t('common:actions')}</h3>
                        </EuiTitle>

                        <EuiFormRow
                            display="columnCompressedSwitch"
                            label={t('alarms:acknowledge_label')}
                        >
                            <EuiSwitch
                                showLabel={false}
                                label={t('alarms:acknowledge_label')}
                                checked={isAcknowledged}
                                disabled={!canAcknowledgeAlarm}
                                compressed
                                onChange={(e) =>
                                    setIsAcknowledged(e.target.checked)
                                }
                            />
                        </EuiFormRow>

                        {isAcknowledged && (
                            <>
                                <EuiFormRow
                                    display="columnCompressedSwitch"
                                    label={t('alarms:pause_label')}
                                >
                                    <EuiSwitch
                                        showLabel={false}
                                        label={t('alarms:pause_label')}
                                        checked={isPaused}
                                        disabled={!canPauseSensor}
                                        compressed
                                        onChange={(e) =>
                                            setIsPaused(e.target.checked)
                                        }
                                    />
                                </EuiFormRow>
                                <EuiFormRow
                                    display="centerCompressed"
                                    label={t('alarms:acknowledge_note')}
                                    fullWidth
                                >
                                    <EuiTextArea
                                        rows={3}
                                        value={note}
                                        readOnly={!canAcknowledgeAlarm}
                                        fullWidth
                                        onChange={(e) =>
                                            setNote(e.target.value)
                                        }
                                    />
                                </EuiFormRow>
                            </>
                        )}
                        {isPaused && (
                            <EuiFormRow display="columnCompressedSwitch">
                                <EuiFlexGroup gutterSize="s">
                                    <EuiFlexItem>
                                        <EuiFieldNumber
                                            min={1}
                                            compressed
                                            defaultValue={amount}
                                            onChange={(e) =>
                                                setAmount(+e.target.value)
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiSelect
                                            compressed
                                            options={TimeUnits.map((tu) => ({
                                                text: t(`common:${tu}`),
                                                value: tu,
                                            }))}
                                            value={unit}
                                            onChange={(e) =>
                                                setUnit(e.target.value as any)
                                            }
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        )}
                    </>
                )}
            </>
        </DetailsPage>
    )
}

export default AlarmDetailsPage
