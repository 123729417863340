import { EuiCollapsibleNavGroup, EuiSkeletonText } from '@elastic/eui'
import { Option, OptionsType, buildOptions } from '@services/options'
import { useEffect, useMemo, useState } from 'react'

import OptionField from './OptionField'
import { useTranslation } from 'react-i18next'

type OptionsEditorGroupProps = {
    optionsType: OptionsType
    options?: Option[]
    onChange: (options: Option[]) => void
    isOpen?: boolean
    isLoading?: boolean
    isDisabled?: boolean
}

const OptionsEditorGroup = (props: OptionsEditorGroupProps) => {
    const { t } = useTranslation(['options'])

    const [editorOptions, setEditorOptions] = useState<Option[] | undefined>(
        undefined
    )

    const options = useMemo(
        () => buildOptions(props.optionsType, editorOptions),
        [props.optionsType, editorOptions]
    )

    const handleOnChange = (option: Option) => {
        let newOptions = [...(editorOptions || [])]
        const index = newOptions.findIndex(
            (o) => o.key === option.key && o.channel === option.channel
        )

        if (index !== -1) {
            newOptions[index] = { ...option }
        } else {
            newOptions = [...newOptions, option]
        }

        setEditorOptions(newOptions)
        props.onChange(newOptions)
    }

    useEffect(() => {
        setEditorOptions(props.options)
    }, [props.options])

    return (
        <EuiCollapsibleNavGroup
            title={<span>{t(`options:${props.optionsType}`)}</span>}
            initialIsOpen={props.isOpen ?? false}
            isCollapsible={true}
        >
            <EuiSkeletonText lines={3} isLoading={props.isLoading}>
                {options &&
                    options.map((op, i) => (
                        <OptionField
                            key={`option-${i}`}
                            option={op}
                            onChange={handleOnChange}
                            isDisabled={props.isDisabled}
                        />
                    ))}
            </EuiSkeletonText>
        </EuiCollapsibleNavGroup>
    )
}
export default OptionsEditorGroup
