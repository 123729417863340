import {
    DetailsSensorRequest,
    SensorType,
    getSensorTypeTranslation,
} from '@services/sensors'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import {
    Option,
    getAlarmOptionsTypeBySensorType,
    getAvailableNotificationTypes,
    getOptionsTypeBySensorType,
} from '@services/options'
import {
    RemoteSelectionBoxField,
    SelectionBoxField,
    SwitchField,
    TextField,
} from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, number, object, string } from 'yup'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    useCreateSensorMutation,
    useFetchCategoriesQuery,
    useFetchDevicesQuery,
    useFindSensorQuery,
    useUpdateSensorMutation,
} from '@services/api'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { ALL_ALARM_CHANNEL_OPTION_TYPES } from '@services/options/options.service'
import { DetailsPage } from '@components/layout'
import { OptionsEditor } from '@components/options'
import { Permissions } from '@services/auth'
import { SensorConfigurator } from '@components/sensors'
import { getNumberOrDefault } from '@utils/numbers'
import { getOptionsByEnum } from '@utils/enums'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const sensorSchema = object({
    name: string().required(),
    type: string().required(),
    isEnabled: boolean().required(),
    deviceId: number().required(),
    categoryId: number().nullable().optional(),
    options: array().optional(),
})

const SensorDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'sensors'])

    // eslint-disable-next-line prefer-const
    let { id } = useParams()
    const [searchParams] = useSearchParams([])

    // States
    const [skip, setSkip] = useState(true)
    const [selectedTemplateId, setSelectedTemplateId] = useState<
        number | undefined
    >(undefined)
    const [autoNameParams, setAutoNameParams] = useState({
        deviceName: '',
        sensorType: SensorType.Ping, // Initial value for the dropdown
    })
    const [showConfigurator, setShowConfigurator] = useState<boolean>(
        !id || id === 'new'
    )

    const canViewCategories = useHasPermission(Permissions.category.canView)
    const canCreateSensors = useHasPermission(Permissions.sensor.canCreate)
    const canUpdateSensors = useHasPermission(Permissions.sensor.canUpdate)

    // Queries
    const { data, isLoading } = useFindSensorQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )
    const { data: sensorTemplate } = useFindSensorQuery(
        { id: getNumberOrDefault(selectedTemplateId, 0) },
        { skip: selectedTemplateId === undefined }
    )

    // Mutations
    const [createSensor, { isLoading: isCreating }] = useCreateSensorMutation()
    const [updateSensor, { isLoading: isUpdating }] = useUpdateSensorMutation()

    const isEditing = useMemo(() => !!data, [data])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateSensors) return false
        if (!isEditing && canCreateSensors) return false

        return true
    }, [isEditing, canCreateSensors, canUpdateSensors])

    const notificationTypes = getAvailableNotificationTypes()

    const onSubmit = async (record: DetailsSensorRequest) => {
        try {
            if (data && id) {
                await updateSensor({ ...record, id: +id } as any)
            } else {
                const mappedOptions = record.options?.map((option) => ({
                    ...option,
                    id: undefined,
                }))

                await createSensor({ ...record, options: mappedOptions } as any)
            }

            if (searchParams.get('referral') == 'network') navigate('/network')
            else navigate('/sensors')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<DetailsSensorRequest>({
        initialValues: {
            name: '',
            isPaused: false,
            pausedUntil: undefined,
            type: SensorType.Ping,
            isEnabled: true,
            isTemplate: false,
            deviceId: 0,
            options: [],
        },
        validationSchema: sensorSchema,
        onSubmit,
    })

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key',
            (o: Option) => {
                if (ALL_ALARM_CHANNEL_OPTION_TYPES.indexOf(o.key) > -1)
                    return 'channel'
            }
        )
        form.setFieldValue('options', newOptions)
    }

    const linkDeviceFromParams = useCallback(() => {
        if (searchParams) {
            const deviceId = searchParams.get('deviceId')
            if (deviceId) form.setFieldValue('deviceId', parseInt(deviceId))
        }
    }, [searchParams])

    // Memos
    const optionsType = useMemo(
        () => getOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )
    const alarmOptionsTypes = useMemo(
        () => getAlarmOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )

    useEffect(() => {
        if (id && !isNaN(+id)) {
            setSkip(false)
        } else {
            linkDeviceFromParams()
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    useEffect(() => {
        if (sensorTemplate) {
            form.setValues(sensorTemplate)
            linkDeviceFromParams()
        }
    }, [sensorTemplate])

    /**
     * Sets the name value automatically based on the device name and the sensor type.
     * This is only used when creating a new sensor before manually entering the name.
     */
    useEffect(() => {
        if (!form.touched.name && !!skip) {
            const name = autoNameParams.sensorType
                ? `${autoNameParams.deviceName} ${getSensorTypeTranslation(
                      autoNameParams.sensorType
                  )}`
                : autoNameParams.deviceName
            form.setFieldValue('name', name)
        }
    }, [autoNameParams])

    return (
        <>
            {showConfigurator ? (
                <SensorConfigurator
                    onCancel={() => navigate('..')}
                    onConfirm={(selectedTemplateId: number | undefined) => {
                        setSelectedTemplateId(selectedTemplateId)
                        setShowConfigurator(false)
                    }}
                />
            ) : (
                <DetailsPage
                    onClose={() => navigate('..')}
                    size="s"
                    title={
                        isEditing && canUpdateSensors
                            ? t('sensors:edit_title')
                            : !isEditing && canCreateSensors
                              ? t('sensors:create_title')
                              : t('sensors:view_title')
                    }
                    loading={isLoading || isCreating || isUpdating}
                    submitLoading={isLoading || isCreating || isUpdating}
                    onSave={isFormDisabled ? undefined : form.handleSubmit}
                    onCancel={() => navigate('..')}
                >
                    <Tabs>
                        <Tab title={t('common:details')} id="details">
                            <EuiSpacer />
                            <EuiForm
                                component="form"
                                onSubmit={form.handleSubmit}
                            >
                                <>
                                    <RemoteSelectionBoxField
                                        form={form}
                                        hook={useFetchDevicesQuery}
                                        config={{
                                            append: {
                                                filters: ['isTemplate==false'],
                                            },
                                            search: { field: 'name' },
                                        }}
                                        name="deviceId"
                                        label={t('sensors:device')}
                                        fullWidth
                                        isClearable={false}
                                        value={
                                            !form.touched.deviceId
                                                ? data?.deviceId
                                                : undefined
                                        }
                                        isDisabled={isFormDisabled}
                                        onChange={(value) => {
                                            form.setFieldValue(
                                                'deviceId',
                                                value[0]?.value
                                            )
                                            setAutoNameParams({
                                                ...autoNameParams,
                                                deviceName: value[0]?.label,
                                            })
                                            if (form.values.deviceId)
                                                form.setFieldTouched('deviceId')
                                        }}
                                    />
                                    <SelectionBoxField
                                        form={form}
                                        name="type"
                                        label={t('sensors:type')}
                                        fullWidth
                                        isClearable={false}
                                        value={form.values.type}
                                        disabled={isFormDisabled}
                                        onChange={(value) => {
                                            form.setFieldValue(
                                                'type',
                                                value[0]?.value
                                            )
                                            setAutoNameParams({
                                                ...autoNameParams,
                                                sensorType: value[0]?.value,
                                            })
                                        }}
                                        options={
                                            getOptionsByEnum(SensorType).map(
                                                ({ value }) => ({
                                                    value,
                                                    label: getSensorTypeTranslation(
                                                        value
                                                    ),
                                                })
                                            ) ?? []
                                        }
                                    />
                                    <TextField
                                        form={form}
                                        name="name"
                                        fullWidth
                                        label={t('sensors:name')}
                                        placeholder={t('sensors:name')}
                                        isDisabled={isFormDisabled}
                                    />

                                    {canViewCategories && (
                                        <RemoteSelectionBoxField
                                            form={form}
                                            hook={useFetchCategoriesQuery}
                                            config={{
                                                search: { field: 'name' },
                                            }}
                                            name="categoryId"
                                            label={t('sensors:category')}
                                            fullWidth
                                            isClearable={false}
                                            value={form.values.categoryId}
                                            isDisabled={isFormDisabled}
                                            onChange={(value) =>
                                                form.setFieldValue(
                                                    'categoryId',
                                                    value[0]?.value
                                                )
                                            }
                                        />
                                    )}
                                    <SwitchField
                                        form={form}
                                        name="isEnabled"
                                        label={t('sensors:is_enabled')}
                                        value={form.values.isEnabled}
                                        isDisabled={isFormDisabled}
                                        onChange={(value) =>
                                            form.setFieldValue(
                                                'isEnabled',
                                                value
                                            )
                                        }
                                    />
                                </>
                            </EuiForm>
                        </Tab>
                        <Tab
                            title={t('common:settings')}
                            id="settings"
                            hide={!optionsType ? true : undefined}
                        >
                            <OptionsEditor
                                source="sensor"
                                sourceId={
                                    selectedTemplateId
                                        ? selectedTemplateId
                                        : id === 'new'
                                          ? 0
                                          : id
                                }
                                inheritanceSourceId={
                                    !selectedTemplateId && id === 'new'
                                        ? form.values.deviceId
                                        : undefined
                                }
                                optionsGroups={optionsType ? optionsType : []}
                                onChange={handleOptionsChange}
                                isDisabled={isFormDisabled}
                            />
                        </Tab>
                        <Tab
                            title={t('common:alarms')}
                            hide={skip ? true : undefined}
                        >
                            <OptionsEditor
                                source="sensor"
                                sourceId={selectedTemplateId || id}
                                optionsGroups={
                                    alarmOptionsTypes ? alarmOptionsTypes : []
                                }
                                onChange={handleOptionsChange}
                                isDisabled={isFormDisabled}
                            />
                        </Tab>
                        <Tab title={t('common:notifications')}>
                            <OptionsEditor
                                source="sensor"
                                sourceId={selectedTemplateId || id}
                                optionsGroups={notificationTypes}
                                onChange={handleOptionsChange}
                                isDisabled={isFormDisabled}
                            />
                        </Tab>
                    </Tabs>
                </DetailsPage>
            )}
        </>
    )
}

export default SensorDetailsPage
