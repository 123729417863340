import { Outlet, useNavigate } from 'react-router'
import {
    Query,
    getDefaultQuery,
    useDeleteTenantMutation,
    useDeleteTenantsMutation,
    useFetchTenantsQuery,
} from '@services/api'
import { Table, TableColumn } from '@components/table'

import { EuiButton } from '@elastic/eui'
import { Page } from '@components/layout'
import { Tenant } from '@services/tenants'
import { useHasDependentPermissions } from '@hooks/auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const TenantsPage = () => {
    const { t } = useTranslation(['common', 'tenants'])
    const [query, setQuery] = useState<Query>(getDefaultQuery())

    const canCreateTenant = useHasDependentPermissions('tenant', [
        'canView',
        'canCreate',
    ])
    const canUpdateTenant = useHasDependentPermissions('tenant', [
        'canView',
        'canUpdate',
    ])
    const canDeleteTenant = useHasDependentPermissions('tenant', ['canDelete'])

    const { data, isLoading } = useFetchTenantsQuery(query)
    const [deleteTenant, { isLoading: isDeleteLoading }] =
        useDeleteTenantMutation()
    const [deleteTenants, { isLoading: isDeletesLoading }] =
        useDeleteTenantsMutation()
    const navigate = useNavigate()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('tenants:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('tenants:filter_by_name'),
        },
        {
            field: 'isEnabled',
            name: t('tenants:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('tenants:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateTenant ? 'onEdit' : 'onView']: (tenant: Tenant) =>
                navigate(`/tenants/${tenant.id}`),
            onDelete: canDeleteTenant
                ? (tenant: Tenant) => deleteTenant(tenant.id)
                : undefined,
        },
    ]

    const handleOnChange = (query: Query) => setQuery({ ...query })

    return (
        <>
            <Outlet />
            <Page
                title={t('tenants:title')}
                iconType="managementApp"
                items={
                    canCreateTenant
                        ? [
                              <EuiButton
                                  key="btn_new_tenant"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/tenants/new')}
                              >
                                  {t('tenants:new_tenant')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<Tenant>
                    loading={isLoading || isDeleteLoading || isDeletesLoading}
                    items={data?.items ?? []}
                    totalCount={data?.totalCount ?? 0}
                    columns={columns}
                    onFilter={(filters) => setQuery({ ...query, filters })}
                    onFilterClear={() => setQuery({ ...query, filters: [] })}
                    onChange={handleOnChange}
                    selectable={canDeleteTenant}
                    itemSelectable={() => true}
                    deleteSelectedRows={
                        canDeleteTenant
                            ? (items) => deleteTenants(items.map((x) => x.id))
                            : undefined
                    }
                />
            </Page>
        </>
    )
}

export default TenantsPage
