export const Permissions = Object.freeze({
    agent: {
        canView: 'Agent.AgentsCanView',
        canCreate: 'Agent.AgentsCanCreate',
        canUpdate: 'Agent.AgentsCanUpdate',
        canDelete: 'Agent.AgentsCanDelete',
        canPause: 'Agent.AgentsCanPause',
        canViewDebugLogs: 'Agent.AgentsCanViewDebugLogs',
        canEnableTroubleshoot: 'Agent.AgentsCanEnableTroubleshoot',
        canTriggerUpdate: 'Agent.AgentsCanTriggerUpdate',
    },
    alarm: {
        canView: 'Alarm.AlarmsCanView',
        canAcknowledge: 'Alarm.AlarmsCanAcknowledge',
        canViewGlobal: 'Alarm.AlarmsCanViewGlobal',
        canAcknowledgeGlobal: 'Alarm.AlarmsCanAcknowledgeGlobal',
    },
    category: {
        canView: 'Category.CategoriesCanView',
        canCreate: 'Category.CategoriesCanCreate',
        canUpdate: 'Category.CategoriesCanUpdate',
        canDelete: 'Category.CategoriesCanDelete',
    },
    device: {
        canView: 'Device.DevicesCanView',
        canCreate: 'Device.DevicesCanCreate',
        canUpdate: 'Device.DevicesCanUpdate',
        canDelete: 'Device.DevicesCanDelete',
        canPause: 'Device.DevicesCanPause',
        canViewTemplates: 'Device.DeviceTemplatesCanView',
        canCreateTemplates: 'Device.DeviceTemplatesCanCreate',
        canUpdateTemplates: 'Device.DeviceTemplatesCanUpdate',
        canDeleteTemplates: 'Device.DeviceTemplatesCanDelete',
    },
    group: {
        canView: 'Group.GroupsCanView',
        canCreate: 'Group.GroupsCanCreate',
        canUpdate: 'Group.GroupsCanUpdate',
        canDelete: 'Group.GroupsCanDelete',
    },
    role: {
        canView: 'Role.RolesCanView',
        canCreate: 'Role.RolesCanCreate',
        canUpdate: 'Role.RolesCanUpdate',
        canDelete: 'Role.RolesCanDelete',
    },
    user: {
        canView: 'User.UsersCanView',
        canCreate: 'User.UsersCanCreate',
        canUpdate: 'User.UsersCanUpdate',
        canDelete: 'User.UsersCanDelete',
    },
    network: {
        canView: 'Network.NetworkCanView',
        canViewGlobal: 'Network.NetworkCanViewGlobal',
    },
    sensor: {
        canView: 'Sensor.SensorsCanView',
        canCreate: 'Sensor.SensorsCanCreate',
        canUpdate: 'Sensor.SensorsCanUpdate',
        canDelete: 'Sensor.SensorsCanDelete',
        canPause: 'Sensor.SensorsCanPause',
        canViewTemplates: 'Sensor.SensorTemplatesCanView',
        canCreateTemplates: 'Sensor.SensorTemplatesCanCreate',
        canUpdateTemplates: 'Sensor.SensorTemplatesCanUpdate',
        canDeleteTemplates: 'Sensor.SensorTemplatesCanDelete',
    },
    tenant: {
        canView: 'Tenant.TenantsCanView',
        canCreate: 'Tenant.TenantsCanCreate',
        canUpdate: 'Tenant.TenantsCanUpdate',
        canDelete: 'Tenant.TenantsCanDelete',
    },
})

export type PermissionType = typeof Permissions
type PermissionValueType = PermissionType[keyof PermissionType]

type KeysUnion<T> = T extends T ? keyof T : never

export type PermissionValueKeyType = KeysUnion<PermissionValueType>

type PermissionDependenciesType = {
    [key in keyof Partial<PermissionType>]: {
        [key in KeysUnion<PermissionValueType>]?: string[]
    }
}

// These are the minimum permissions required for the UI to function correctly for a certain permissions
export const PermissionDependencies: PermissionDependenciesType = {
    agent: {
        canView: [Permissions.tenant.canView],
        canEnableTroubleshoot: [Permissions.agent.canViewDebugLogs],
    },
    device: {
        canView: [Permissions.agent.canView],
    },
    sensor: {
        canView: [Permissions.device.canView],
    },
    group: {
        canView: [Permissions.tenant.canView],
    },
    user: {
        canView: [Permissions.tenant.canView],
    },
}

export const getPermissionWithDependencies: (
    group: keyof PermissionType,
    permissions: KeysUnion<PermissionValueType>[]
) => string[] = (group, permissions) => [
    ...permissions
        .map((p) => [
            Permissions[group][p as keyof PermissionValueType],
            ...(PermissionDependencies[group]?.[p] || []),
        ])
        .flat()
        .filter((x, i, arr) => arr.indexOf(x) === i),
]
