import { CollectionResult, Query } from '../interfaces'
import {
    DetailsDeviceRequest,
    DetailsDeviceTemplateRequest,
    Device,
} from '@services/devices'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const deviceApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchDevices: builder.query<CollectionResult<Device>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Device'),
            query: (q) => ({
                url: 'devices',
                method: 'GET',
                params: buildQueryParams(q, ['agent']),
            }),
        }),
        findDevice: builder.query<Device, { id: number }>({
            providesTags: (result) => provideCacheTag(result, 'Device'),
            query: (q) => ({
                url: `devices/${q.id}`,
                method: 'GET',
            }),
        }),
        createDevice: builder.mutation<Device, DetailsDeviceRequest>({
            query: (req) => ({
                url: 'devices',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: () =>
                invalidateCacheTag('Device')
                    .concat(['Option'])
                    .concat(['AgentNetworkTree'])
                    .concat(['GroupNetworkTree']),
        }),
        updateDevice: builder.mutation<Device, Device>({
            query: (req) => ({
                url: `devices/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Device', result?.id).concat(['Option']),
        }),
        addSensorsToDevice: builder.mutation<
            void,
            { id: string; sensors: any[] }
        >({
            query: (req) => ({
                url: `devices/${req.id}/sensors`,
                method: 'POST',
                body: {
                    sensors: req.sensors,
                },
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Device', req).concat([
                    'Option',
                    'GroupNetworkTree',
                    'AgentNetworkTree',
                ]),
        }),
        deleteDevice: builder.mutation<void, number>({
            query: (id) => ({
                url: `devices/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Device', req).concat(['Option']),
        }),
        deleteDevices: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: 'devices',
                method: 'DELETE',
                body: {
                    deviceIds: ids,
                },
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Device', req).concat(['Option']),
        }),
        findDeviceTemplate: builder.query<Device, { id: number }>({
            providesTags: (result) => provideCacheTag(result, 'Device'),
            query: (q) => ({
                url: `devices/templates/${q.id}`,
                method: 'GET',
            }),
        }),
        createDeviceTemplate: builder.mutation<
            void,
            DetailsDeviceTemplateRequest
        >({
            query: (req) => ({
                url: 'devices/templates',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: () =>
                invalidateCacheTag('Device').concat(['Option']),
        }),
        updateDeviceTemplate: builder.mutation<Device, Device>({
            query: (req) => ({
                url: `devices/templates/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Device', result?.id).concat(['Option']),
        }),
    }),
})

export const {
    useFetchDevicesQuery,
    useFindDeviceQuery,
    useFindDeviceTemplateQuery,
    useCreateDeviceMutation,
    useCreateDeviceTemplateMutation,
    useUpdateDeviceMutation,
    useUpdateDeviceTemplateMutation,
    useDeleteDeviceMutation,
    useDeleteDevicesMutation,
    useAddSensorsToDeviceMutation,
} = deviceApi
