import { EuiButton, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import {
    Query,
    getDefaultQuery,
    useDeleteDeviceMutation,
    useDeleteDevicesMutation,
    useFetchAgentsQuery,
    useFetchDevicesQuery,
} from '@services/api'
import { Table, TableColumn } from '@components/table'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Device } from '@services/devices'
import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DeviceTemplatesPage = () => {
    const { t } = useTranslation(['common', 'devices'])
    const navigate = useNavigate()

    // States
    const [query, setQuery] = useState<Query>({
        ...getDefaultQuery(),
        filters: ['isTemplate==true'],
    })

    // Permissions
    const canCreateDeviceTemplates = useHasDependentPermissions('device', [
        'canViewTemplates',
        'canCreateTemplates',
    ])
    const canUpdateDeviceTemplates = useHasDependentPermissions('device', [
        'canViewTemplates',
        'canUpdateTemplates',
    ])
    const canDeleteDeviceTemplates = useHasDependentPermissions('device', [
        'canDeleteTemplates',
    ])

    const canViewDevices = useHasPermission(Permissions.device.canView)
    const canViewAgents = useHasPermission(Permissions.agent.canView)

    // Queries
    const { data: agents } = useFetchAgentsQuery({}, { skip: !canViewAgents })
    const { data: devices, isLoading: devicesLoading } =
        useFetchDevicesQuery(query)

    // Mutations
    const [deleteDevice, { isLoading: deleteDeviceLoading }] =
        useDeleteDeviceMutation()
    const [deleteDevices, { isLoading: deleteDevicesLoading }] =
        useDeleteDevicesMutation()

    const columns: TableColumn[] = [
        {
            field: 'templateName',
            name: t('devices:template_name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('devices:filter_by_template_name'),
        },
        {
            field: 'isEnabled',
            name: t('devices:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('devices:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        ...(canViewAgents
            ? [
                  {
                      field: 'agent.name',
                      name: t('devices:agent'),
                      sortable: true,
                      filterable: true,
                      filterField: 'agentId',
                      filterType: 'options',
                      filterPlaceholder: t('devices:filter_by_agent'),
                      filterOptions:
                          agents?.items?.map((item) => ({
                              label: item.name,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateDeviceTemplates ? 'onEdit' : 'onView']: (
                device: Device
            ) => navigate(`/deviceTemplates/${device.id}`),
            onDelete: canDeleteDeviceTemplates
                ? (device: Device) => deleteDevice(device.id)
                : undefined,
        },
    ]

    const handleOnChange = (query: Query) => setQuery({ ...query })

    return (
        <>
            <Outlet />
            <Page
                title={t('devices:templates_title')}
                iconType="managementApp"
                items={
                    canCreateDeviceTemplates
                        ? [
                              <EuiButton
                                  key="btn_new_device_template"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() =>
                                      navigate('/deviceTemplates/new')
                                  }
                              >
                                  {t('devices:new_device_template')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <>
                    <EuiTabs>
                        {canViewDevices && (
                            <EuiTab
                                key="devices"
                                name="Devices"
                                onClick={() => navigate('/devices')}
                            >
                                {t('devices:title')}
                            </EuiTab>
                        )}
                        <EuiTab
                            key="device-templates"
                            name="Device templates"
                            isSelected={true}
                        >
                            {t('devices:templates_title')}
                        </EuiTab>
                    </EuiTabs>
                    <EuiSpacer size="s" />
                    <Table<Device>
                        loading={
                            devicesLoading ||
                            deleteDeviceLoading ||
                            deleteDevicesLoading
                        }
                        items={devices?.items ?? []}
                        totalCount={devices?.totalCount ?? 0}
                        columns={columns}
                        onFilter={(filters) => setQuery({ ...query, filters })}
                        onFilterClear={() =>
                            setQuery({ ...query, filters: [] })
                        }
                        onChange={handleOnChange}
                        selectable={canDeleteDeviceTemplates}
                        itemSelectable={() => true}
                        deleteSelectedRows={
                            canDeleteDeviceTemplates
                                ? (items) =>
                                      deleteDevices(items.map((x) => x.id))
                                : undefined
                        }
                    />
                </>
            </Page>
        </>
    )
}

export default DeviceTemplatesPage
